const styles = theme => ({
  partsCategoryCardRow: {
    padding: "24px 0 0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("md")]: {
      padding: "48px 0",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "90px 0",
    },
    "& .wrapper": {
      "& > .MuiTypography-root": {
        marginBottom: 32,
        color: theme.palette.tertiary.contrastText,
      },
      "& .cards": {
        display: "flex",
        flex: "0 0 100%",
        flexWrap: "wrap",
        flexDirection: "row",

        [theme.breakpoints.up("md")]: {
          flexWrap: "nowrap",
        },
      },
    },
  },
})

export default styles
