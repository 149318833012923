import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import PreviewImage from "../PreviewImage"
import Link from "../Link"

import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const PartsCategoryBrandLogos = ({ brands }) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query partsCategoryBrandLogoComponent {
      wp {
        themeOptions {
          ThemeOptions {
            brandLogo {
              backgroundColour
              brandBackgroundColour
              headerColour
              activeIconColour
              textColour
            }
          }
        }
      }
    }
  `)

  const overrides = data.wp.themeOptions.ThemeOptions.brandLogo

  return (
    <div
      className={classes.partsCategoryBrandLogos}
      data-override-background={
        overrides.backgroundColour !== "background"
          ? overrides.backgroundColour
          : null
      }
    >
      <div className="brand-list grid">
        {brands.map(brand => {
          return (
            <div key={brand.id}>
              <Link
                to={brand.uri}
                data-override-background={
                  overrides.brandBackgroundColour !== "primary"
                    ? overrides.brandBackgroundColour
                    : null
                }
              >
                <PreviewImage
                  image={brand.BrandExtras.image.localFile}
                  fallbackUrl={brand.BrandExtras.image.sourceUrl}
                  alt={`${brand.name} logo`}
                  fixedPadding="80%"
                />
                <span
                  data-override-color={
                    overrides.textColour !== "on-primary"
                      ? overrides.textColour
                      : null
                  }
                >
                  {brand.name}
                </span>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}
