import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
// import SplitPageTitle from "../components/SplitPageTitle"
import PartsCategoryCardRow from "../components/PartsCategoryCardRow"
// import PreviewImage from "../components/PreviewImage"
import { PartsCategoryBrandLogos } from "../components/PartsCategoryBrandLogos"
import { BblBrandDetailBlock } from "../components/blocks/BblBrandDetailBlock"

class Post extends Component {
  render() {
    const productCategory = this.props.data.wpProductCategory
    const overrides = this.props.data.wp.themeOptions.ThemeOptions.brandDetail

    const { brands } = this.props.data.wpProductCategory.ProductCategoryExtras

    return (
      <Layout
        meta={{}}
        path={this.props.pageContext.pagePath}
        title={productCategory.name}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <div style={{ padding: "80px 0 64px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "0 auto",
              maxWidth: 1142,
              width: "100%",
            }}
          >
            <h1
              style={{
                fontSize: 48,
                lineHeight: "1.16",
                marginBottom: 16,
              }}
            >
              {productCategory.name}
            </h1>
          </div>
        </div>
        <BblBrandDetailBlock
          pageContext={{
            brand: {
              name: productCategory.description,
              BrandExtras: {
                image: productCategory.ProductCategoryExtras.image,
              },
              description: productCategory.description,
            },
            productCategories: [],
          }}
          attributes={{}}
          data-override-background={
            overrides.backgroundColour !== "background"
              ? overrides.backgroundColour
              : null
          }
        />
        {/* <SplitPageTitle
          className={`inner`}
          image={
            <PreviewImage
              image={productCategory.ProductCategoryExtras.image.localFile}
              fallbackUrl={
                productCategory.ProductCategoryExtras.image.sourceUrl
              }
              alt={productCategory.name}
              fixedPadding="100%"
            />
          }
        >
          <h1>{productCategory.name}</h1>
          <p>{productCategory.description}</p>
        </SplitPageTitle> */}
        <PartsCategoryCardRow
          childCategories={productCategory.wpChildren.nodes}
        />
        {brands && (
          <div className="wrapper">
            <h2>Brands we stock</h2>
            <PartsCategoryBrandLogos brands={brands} />
          </div>
        )}
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String) {
    wpProductCategory(id: { eq: $id }) {
      id
      name
      description
      ProductCategoryExtras {
        type
        brands {
          id
          name
          uri
          BrandExtras {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 992
                    aspectRatio: 1.78
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                    quality: 90
                  )
                }
              }
              sourceUrl
            }
          }
        }
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 640
                aspectRatio: 1.78
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { fit: COVER, cropFocus: CENTER }
                quality: 90
              )
            }
          }
        }
      }
      wpChildren {
        nodes {
          id
          name
          slug
          uri
          description
          ProductCategoryExtras {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 992
                    aspectRatio: 1.78
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                    quality: 90
                  )
                }
              }
            }
          }
        }
      }
      products {
        nodes {
          title
          uri
          product {
            productGallery {
              image {
                sourceUrl
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
          brandDetail {
            backgroundColour
            textColour
            partsHeaderColour
            partsBackgroundColour
            partsTextColour
          }
        }
      }
    }
  }
`

export default Post
