const styles = theme => ({
  partsCategoryCard: {
    borderRadius: theme.border.radius,
    overflow: "hidden",
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadow,
    flex: "0 0 calc(100% - 24px)",
    margin: "0 12px 24px",

    [theme.breakpoints.up("sm")]: {
      flex: "0 0 calc(50% - 48px);",
      maxWidth: "50%",
      margin: 12,
    },
    [theme.breakpoints.up("md")]: {
      // padding: "0 0 80px",
      flex: "0 0 25%",
      maxWidth: "25%",
      flexWrap: "nowrap",
    },
    "& .con-wrap": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: 32,
      "& .min-height": {
        minHeight: 150,
        paddingBottom: 1,
      },
      "& h1, & h2, & h3, & h4, & h5, & h6, & p": {
        color: theme.palette.secondary.contrastText,
        padding: 0,
      },
      "& p": {
        display: "-webkit-box",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    "& .subcat-btn": {
      marginLeft: 0,
    },
    "& .links": {
      "& > a": {
        margin: 0,
      },
    },
  },
})
export default styles
