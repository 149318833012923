import React from "react"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"
import PreviewImage from "../../PreviewImage"
import { AcfButtonBlock } from "../../blocks/AcfButtonBlock"

const useStyles = makeStyles(theme => styles(theme))

const PartsCategoryCard = ({ subCategory }) => {
  const classes = useStyles()

  return (
    <div className={`${classes.partsCategoryCard}`}>
      <PreviewImage
        image={subCategory.ProductCategoryExtras.image.localFile}
        alt={subCategory.name}
        className={subCategory.name}
      />

      <div className="con-wrap">
        <div className="min-height">
          <h2>{subCategory.name}</h2>
          <p>{subCategory.description}</p>
        </div>
        <AcfButtonBlock
          attributes={{
            className: "subcat-btn",
          }}
          block={{
            ButtonFields: {
              buttonLink: {
                target: "_blank",
                title: subCategory.name,
                url: subCategory.uri,
              },
              buttonStyle: "primary",
              icon: "none",
              showIcon: "",
            },
          }}
          innerBlocks={[]}
        />
      </div>
    </div>
  )
}

export default PartsCategoryCard
