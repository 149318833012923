import React from "react"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"
import PartsCategoryCard from "./PartsCategoryCard"

const useStyles = makeStyles(theme => styles(theme))

const PartsCategoryCardRow = ({ childCategories }) => {
  const classes = useStyles()

  return (
    <div className={`${classes.partsCategoryCardRow}`}>
      {childCategories.map(subCategory => {
        return <PartsCategoryCard subCategory={subCategory} />
      })}
    </div>
  )
}

export default PartsCategoryCardRow
